<!-- QRCodeModal.vue -->
<template>
  <v-dialog v-model="localValue" class="w-11/12 max-w-md">
    <v-card class="rounded-xl overflow-hidden">
      <!-- Header -->
      <div class="bg-gray-50 px-6 py-4 border-b flex justify-between items-center">
        <h3 class="text-lg font-semibold text-gray-900">{{ text }}</h3>
        <button @click="$emit('update:modelValue', false)" class="text-gray-400 hover:text-gray-500 transition-colors">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!-- Content -->
      <div class="p-6 flex flex-col items-center">
        <!-- QR Code Container with enhanced styling -->
        <div class="bg-white p-4 rounded-xl shadow-inner mb-6 relative">
          <!-- Corner decorations -->
          <div class="absolute top-0 left-0 w-4 h-4 border-t-2 border-l-2 border-blue-500 -mt-1 -ml-1"></div>
          <div class="absolute top-0 right-0 w-4 h-4 border-t-2 border-r-2 border-blue-500 -mt-1 -mr-1"></div>
          <div class="absolute bottom-0 left-0 w-4 h-4 border-b-2 border-l-2 border-blue-500 -mb-1 -ml-1"></div>
          <div class="absolute bottom-0 right-0 w-4 h-4 border-b-2 border-r-2 border-blue-500 -mb-1 -mr-1"></div>

          <!-- QR Code -->
          <vue-qrcode :text="link" :options="{ width: 256, margin: 1 }" class="mx-auto"></vue-qrcode>
        </div>

        <!-- URL Display and Copy Section -->
        <div class="w-full space-y-3">
          <!-- URL Display -->
          <div class="flex items-center justify-between bg-gray-50 px-4 py-2 rounded-lg border">
            <span class="text-gray-600 text-sm truncate mr-2">{{ link }}</span>
            <button @click="copyToClipboard"
              class="text-blue-600 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 p-1 rounded">
              <svg v-if="!copied" class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
              </svg>
              <svg v-else class="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </button>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-center space-x-3">
            <a :href="link" target="_blank" rel="noopener noreferrer"
              class="inline-flex items-center px-4 py-2 border border-blue-600 rounded-lg text-blue-600 hover:bg-blue-50 transition-colors">
              <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
              {{ $t('openInBrowser') }}
            </a>
            <button @click="$emit('update:modelValue', false)"
              class="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors">
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQrcode from 'vue-qrcode-component';

export default {
  name: 'QRCodeModal',
  components: {
    VueQrcode,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      copied: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.link);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    }
  },
  watch: {
    modelValue(newVal) {
      if (!newVal) {
        this.copied = false;
      }
    }
  }
}
</script>

<style scoped>
.v-dialog {
  border-radius: 1rem !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.v-card {
  animation: fadeIn 0.2s ease-out;
}
</style>