// Description: The main Vuex store file. It contains the store object and the logic to handle the user authentication state. store/index.js
import { createStore } from 'vuex'
import { rtdb, auth, functions } from '../firebaseInit';
import { ref, get, onValue, update, off, onDisconnect, remove, set } from 'firebase/database';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js'
import { httpsCallable } from 'firebase/functions';
import i18n from '../languages/i18n';

const waitForRef = (reference, timeout = 5000) => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            cleanup();
            reject(new Error('Timeout waiting for user data'));
        }, timeout);

        const cleanup = () => {
            clearTimeout(timeoutId);
            off(reference);
        };

        onValue(reference, (snapshot) => {
            if (snapshot.exists()) {
                cleanup();
                resolve(snapshot.val());
            }
        }, (error) => {
            cleanup();
            reject(error);
        });
    });
};

const store = createStore({
    state: {
        user: null,
        userDataReady: false,
        authIsReady: false,
        tier: 'Free',
        language: 'de',
        planActive: false,
        conferenceId: null,
        liveTranslationActive: false,
        snackbar: false,
        snackbarMessage: '',
        snackbarTimeout: 5000,
        showQRModal: false,
        qrCodeLink: '',
        qrCodeText: '',
    },
    mutations: {
        async setUser(state, user) {
            state.user = user;
            if (user) {
                try {
                    const userRef = ref(rtdb, `users/${user.uid}`);
                    const userData = await waitForRef(userRef);

                    store.commit('setConferenceId', userData.conferenceId);
                    store.commit('setTier', userData.subscription);
                    store.commit('setIsAnnualSubscription', userData.isAnnualSubscription);
                    store.commit('setPlanActive', userData.planActive);
                    store.commit('setLanguage', userData.language || 'de');
                    store.commit('setPresence');
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    // Handle the error appropriately, e.g., show a notification to the user
                }
            }
        },
        setUserDataReady(state, ready) {
            state.userDataReady = ready;
        },
        setTier(state, tier) {
            state.tier = tier;
        },
        setIsAnnualSubscription(state, isAnnualSubscription) {
            state.isAnnualSubscription = Boolean(isAnnualSubscription);
        },
        setPlanActive(state, value) {
            state.planActive = value;
        },
        setAuthIsReady(state, value) {
            state.authIsReady = value;
        },
        setConferenceId(state, conferenceId) {
            state.conferenceId = conferenceId;
        },
        setLiveTranslationActive(state, value) {
            state.liveTranslationActive = value;
        },
        setSnackbar(state, value) {
            state.snackbar = value;
        },
        setSnackbarMessage(state, message) {
            state.snackbarMessage = message;
        },
        setSnackbarTimeout(state, timeout) {
            state.snackbarTimeout = timeout;
        },
        setShowQRModal(state, value) {
            state.showQRModal = value;
        },
        setQRCodeLink(state, link) {
            state.qrCodeLink = link;
        },
        setQRCodeText(state, text) {
            state.qrCodeText = text;
        },
        setLanguage(state, language) {
            state.language = language
            i18n.global.locale = language;
        },
        setPresence(state) {
            const presenceRef = ref(rtdb, `conferences/${state.conferenceId}/presence`);
            if (window.innerWidth > 768) {
                update(presenceRef, { desktop: 'online' });
                onDisconnect(presenceRef).update({ desktop: 'offline' });
            } else {
                update(presenceRef, { mobile: 'online' });
                onDisconnect(presenceRef).update({ mobile: 'offline' });
            }
        },
    },
    actions: {
        async sendEmail({ dispatch }, { name, email, subject, message }) {
            try {
                const sendEmailFunction = httpsCallable(functions, 'sendEmail');
                const response = await sendEmailFunction({
                    name,
                    email,
                    subject,
                    message
                });

                if (response.data.success) {
                    dispatch('showSnackbar', {
                        message: i18n.global.t('email.successMessage'),
                        timeout: 3000
                    });
                    return { success: true };
                } else {
                    throw new Error('Failed to send email');
                }
            } catch (error) {
                console.error('Error sending email:', error);
                dispatch('showSnackbar', {
                    message: i18n.global.t('email.errorMessage'),
                    timeout: 5000
                });
                throw error;
            }
        },
        async sendPublicContact({ dispatch }, payload) {
            try {
                const sendContactFunction = httpsCallable(functions, 'sendPublicContactForm');
                const response = await sendContactFunction(payload);

                if (response.data.success) {
                    dispatch('showSnackbar', {
                        message: i18n.global.t('contact.successMessage'),
                        timeout: 3000
                    });
                    return { success: true };
                } else {
                    throw new Error('Failed to send contact form');
                }
            } catch (error) {
                console.error('Error sending contact form:', error);
                let errorMessage = i18n.global.t('contact.errorMessage');

                // Spezifische Fehlermeldungen
                if (error.code === 'resource-exhausted') {
                    errorMessage = i18n.global.t('contact.rateLimitError');
                }

                dispatch('showSnackbar', {
                    message: errorMessage,
                    timeout: 5000
                });
                throw error;
            }
        },
        showSnackbar({ commit }, { message, timeout }) {
            commit('setSnackbarTimeout', timeout || 5000);
            commit('setSnackbarMessage', message);
            commit('setSnackbar', true);
        },
        showQRModal({ commit }, { link, text }) {
            commit('setQRCodeLink', link);
            commit('setQRCodeText', text);
            commit('setShowQRModal', true);
        },
        async signUp({ commit }, { name, email, password, congregation, needsSupport, phone }) {
            try {
                // 1. Erstelle Firebase Auth Account
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // 2. Sende Verifizierungs-Email
                await sendEmailVerification(user);

                // 3. Erstelle Nutzerdaten über Cloud Function
                const createUserData = httpsCallable(functions, 'createUserData');
                await createUserData({
                    name,
                    congregation,
                    needsSupport,
                    phone
                });

                // 4. Setze den User im Store
                await commit('setUser', user);
                await this.dispatch('checkUserData');

                return user;
            } catch (error) {
                console.error('Signup error:', error);
                throw error;
            }
        },
        async checkUserData({ commit, state }) {
            if (!state.user) return false;

            try {
                const userRef = ref(rtdb, `users/${state.user.uid}`);
                const userSnapshot = await get(userRef);

                if (!userSnapshot.exists()) {
                    throw new Error('User data not found');
                }

                const userData = userSnapshot.val();
                const conferenceRef = ref(rtdb, `conferences/${userData.conferenceId}`);
                const conferenceSnapshot = await get(conferenceRef);

                if (!conferenceSnapshot.exists()) {
                    throw new Error('Conference data not found');
                }

                commit('setUserDataReady', true);
                return true;
            } catch (error) {
                console.error('Error checking user data:', error);
                commit('setUserDataReady', false);
                return false;
            }
        },
        async updateConferenceDetails({ state }, { name, url }) {
            const conferenceId = state.conferenceId;

            try {
                // Input validation
                if (!name || !url) {
                    throw new Error('Name and URL are required');
                }

                if (url.length < 3 || url.length > 50) {
                    throw new Error('URL must be between 3 and 50 characters');
                }

                // Get current state from database
                const userSnapshot = await get(ref(rtdb, `users/${state.user.uid}`));
                const oldUrl = userSnapshot.val().conferenceUrl;

                // Only proceed with URL mapping update if URL has changed
                if (url !== oldUrl) {
                    const newMappingRef = ref(rtdb, `urlMapping/${url}`);
                    const snapshot = await get(newMappingRef);
                    if (snapshot.exists()) {
                        throw new Error('URL_EXISTS');
                    }

                    // Create new mapping first
                    await set(newMappingRef, { conferenceId });

                    // Then remove old mapping
                    await remove(ref(rtdb, `urlMapping/${oldUrl}`));
                }

                // Update user details
                const userRef = ref(rtdb, `users/${state.user.uid}`);
                await update(userRef, {
                    conferenceName: name,
                    conferenceUrl: url
                });

                // Update conference details
                const conferenceRef = ref(rtdb, `conferences/${conferenceId}`);
                await update(conferenceRef, {
                    conferenceName: name,
                    conferenceUrl: url
                });
                return { success: true };

            } catch (error) {
                console.error('Error updating conference details:', error);

                // Get current state again for rollback (in case it changed)
                const userSnapshot = await get(ref(rtdb, `users/${state.user.uid}`));
                const oldName = userSnapshot.val().conferenceName;
                const oldUrl = userSnapshot.val().conferenceUrl;

                // Rollback changes if something failed
                try {
                    if (url !== oldUrl) {
                        const oldMappingRef = ref(rtdb, `urlMapping/${oldUrl}`);
                        const newMappingRef = ref(rtdb, `urlMapping/${url}`);
                        await set(oldMappingRef, { conferenceId });
                        await remove(newMappingRef);
                    }

                    // Rollback user details
                    const userRef = ref(rtdb, `users/${state.user.uid}`);
                    await update(userRef, {
                        conferenceName: oldName,
                        conferenceUrl: oldUrl
                    });

                    // Rollback conference details
                    const conferenceRef = ref(rtdb, `conferences/${conferenceId}`);
                    await update(conferenceRef, {
                        conferenceName: oldName,
                        conferenceUrl: oldUrl
                    });

                } catch (rollbackError) {
                    console.error('Failed to rollback changes:', rollbackError);
                }

                // Handle specific errors
                if (error.message === 'URL_EXISTS') {
                    throw new Error('This URL is already taken. Please choose another one.');
                }
                throw error;
            }
        },
        async signIn({ commit }, { email, password }) {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            await commit('setUser', userCredential.user);
        },
        async signOut({ commit }) {
            await signOut(auth);
            await commit('setUser', null);
            commit('setConferenceId', null);
        },
        async checkoutPlan(_, { plan, isAnnual, cancelUrl }) {
            const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

            const stripeCheckout = httpsCallable(functions, 'stripeCheckout');
            await stripeCheckout({
                plan: plan,
                isAnnual: isAnnual,
                baseUrl: window.location.origin,
                cancelUrl: cancelUrl,
            })
                .then((result) => {
                    stripe.redirectToCheckout({ sessionId: result.data.sessionId });
                })
                .catch((error) => { console.error('Error:', error.message); });
        }
    },
    modules: {
    }
});


const unsub = onAuthStateChanged(auth, async (user) => {
    if (!user) {
        await store.commit('setUser', null);
        store.commit('setConferenceId', null);
    } else {
        await store.commit('setUser', user);
    }
    store.commit('setAuthIsReady', true);
    unsub();
});

export default store;